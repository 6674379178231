import React, { useState } from "react";
import { Link } from "react-router-dom";
import { deleteTemplate } from "../../store/adminSlice";
import { useDispatch } from "react-redux";
import DeletePopup from "../../common/DeletePopup";

const docxPlaceholder = "https://example.com/path-to-docx-icon.png";

export default function TemplateCard({ item, onTemplateDeleted }) {
  const dispatch = useDispatch();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedTemplateId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteTemplate(selectedTemplateId)).then(() => {
      onTemplateDeleted(selectedTemplateId);
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div className="w-full">
        <div className="relative bg-white dark:bg-secondaryDarkBg rounded-lg shadow-lg overflow-hidden group h-full flex flex-col">
          {/* PDF or DOCX Viewer */}
          {item?.pdfTemplate ? (
            <iframe
              src={`${item?.pdfTemplate}#toolbar=0&navpanes=0&scrollbar=0`}
              title={item?.name}
              className="w-full h-72 object-cover"
              frameBorder="0"
            ></iframe>
          ) : item?.docxTemplate?.endsWith(".docx") ? (
            <iframe
              src={`https://docs.google.com/gview?url=${item?.docxTemplate}&embedded=true&rand=${Math.random()}`}
              title={item?.name}
              className="w-full h-72 object-cover"
            ></iframe>
          ) : (
            <img
              src="/assets/images/no_image.jpg"
              alt={item?.name}
              className="w-full h-40 object-cover"
            />
          )}

          {/* Title and Action Icons */}
          <div className="p-3 flex justify-between items-center border-t border-t-[#EBF0ED]">
            <h2 className="text-base lg:text-xl font-semibold text-gray-800 dark:text-white capitalize">
              {item?.name}
            </h2>

            {/* Update and Delete Icons */}
            <div className="flex items-center space-x-4">
              <Link
                to={`update-template/${item?._id}`}
                className="text-gray-600 hover:text-blue-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
              >
                <img className="max-w-8 max-h-8 md:max-w-16 md:max-h-16 dark:filter dark:invert" src="/assets/icons/edit-icon.svg" alt="" />
              </Link>
              <Link
                to={`view-template/${item?._id}`}
                className="text-gray-600 hover:text-blue-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
              >
                <img className="max-w-8 max-h-8 md:max-w-16 md:max-h-16" src="/assets/icons/view-icon.svg" alt="" />
              </Link>
              <button
                onClick={() => handleDeleteClick(item._id)}
                className="text-gray-600 hover:text-red-600 cursor-pointer p-3 border-[#EBF0ED] dark:border-white border rounded-xl bg-[#FAFAFA] dark:bg-secondaryDarkBg"
              >
                <img
                  className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                  src="/assets/icons/delete-icon.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
