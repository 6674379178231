import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import { ACTIVE_USERS, ADMIN_LOGIN, CLIENT, COMPANIES, CONTRACT, CORE, INACTIVE_USERS, INVOICES, RECOVER_PASSWORD, SECRET_NUMBER, SERVICES, SIGNUP, TEMPLATES, UPDATE_CORE, UPDATE_USER, UPDATE_USER_STATUS, USER_BY_TOKEN, USERS, VISITORS } from "./URLConstant";
import axios from '../functions/axios'


const initialState = {
    data: [],
    loading: false,
    message: null,
    error: null,
    statusCode: null,
    token: localStorage.getItem("token"),
    role: localStorage.getItem("role"),
    signout: false,
    invoiceNextNumber: null,
    invoiceSecretNumber: null,
    companiesForInvoiceForm: null,
    activeUsers: null,
    services: null,
    invoiceByIdData:null,
    companyByIdData:null,
    companyByIdDataPublic: null,
    invoiceByIdDataPublic: null,
    userByUserNameData:null,
    contractXMlResponse:null,
    appLoading: false,
    appMessage: null,
    appError: null,
    appStatusCode: null,
    contractDataForUpdate:null,
};

// *********** RTK Middleware Stare here ***********

// admin Login
export const adminLogin = createAsyncThunk(
    "adminLogin",
    async (login_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(ADMIN_LOGIN, login_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// signup
export const Signup = createAsyncThunk(
    "Signup",
    async (signup_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(SIGNUP, signup_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// forgot Password
export const forgotPassword = createAsyncThunk(
    "forgotPassword",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${RECOVER_PASSWORD}/${data}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get  Companies with pagination
export const getCompanies = createAsyncThunk(
    "getCompanies",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${COMPANIES}?page=${data?.page}&size=${data?.limit}&sort={"name":${data?.sort}}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get All Companies
export const getAllCompanies = createAsyncThunk(
    "getAllCompanies",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(COMPANIES);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get compnay by id
export const getCompanyByID = createAsyncThunk(
    "getCompanyByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${COMPANIES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get compnay by id public
export const getCompanyByIDPublic = createAsyncThunk(
    "getCompanyByIDPublic",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${COMPANIES}/public/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// add company
export const addCompany = createAsyncThunk(
    "addCompany",
    async (company_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(COMPANIES, company_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update company
export const updateCompany = createAsyncThunk(
    "updateCompany",
    async (company_details, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${COMPANIES}/${company_details?.id}`, company_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// delete company
export const deleteCompany = createAsyncThunk(
    "deleteCompany",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${COMPANIES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// search companies
export const searchCompany = createAsyncThunk(
    "searchCompany",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${COMPANIES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get user by token
export const getUserByToken = createAsyncThunk(
    "getUserByToken",
    async (user_token, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${USER_BY_TOKEN}/${user_token}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update user
export const updateUser = createAsyncThunk(
    "updateUser",
    async (user_details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`${UPDATE_USER}/${user_details?.id}`, user_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// Core company setting
export const getCoreCompanySetting = createAsyncThunk(
    "getCoreCompanySetting",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(CORE);
            return {
                data: res.data,
                appStatusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                appError: err.response.data,
                appStatusCode: err.response.status,
            });
        }
    }
);

// update core company setting
export const updateCoreCompanySetting = createAsyncThunk(
    "updateCoreCompanySetting",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.put(UPDATE_CORE, data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all services
export const getAllServices = createAsyncThunk(
    "getAllServices",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(SERVICES);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get all services
export const getAllServicesHome = createAsyncThunk(
    "getAllServicesHome",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SERVICES}?page=${data?.page}&size=${data?.limit}&sort={"title":${data?.sort}}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// add service
export const addService = createAsyncThunk(
    "addService",
    async (service_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(SERVICES, service_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update service
export const updateService = createAsyncThunk(
    "updateService",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${SERVICES}/${data.id}`, data.formData);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get service by id
export const getServiceByID = createAsyncThunk(
    "getServiceByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SERVICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// delete services
export const deleteService = createAsyncThunk(
    "deleteService",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${SERVICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// search services
export const searchService = createAsyncThunk(
    "searchService",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SERVICES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get All Invoices
export const getInvoices = createAsyncThunk(
    "getInvoices",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}?page=${data?.page}&size=${data?.limit}&sort={"number":${data?.sort}}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice by id
export const getInvoiceByID = createAsyncThunk(
    "getInvoiceByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice by id public
export const getInvoiceByIDPublic = createAsyncThunk(
    "getInvoiceByIDPublic",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/public/${data?.number}/${data?.secret}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice number
export const getInvoiceNumber = createAsyncThunk(
    "getInvoiceNumber",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/next`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get invice number
export const getInvoiceSecretNumber = createAsyncThunk(
    "getInvoiceSecretNumber",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${SECRET_NUMBER}/32`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// generate invoice
export const genInvoice = createAsyncThunk(
    "genInvoice",
    async (invoice_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(INVOICES, invoice_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update invoice
export const updateInvoice = createAsyncThunk(
    "updateInvoice",
    async (invoice_details, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${INVOICES}/${invoice_details?.id}`, invoice_details?.payload);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// delete Invoice
export const deleteInvoice = createAsyncThunk(
    "deleteInvoice",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${INVOICES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// search invoice
export const searchInvoice = createAsyncThunk(
    "searchInvoice",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${INVOICES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all user
export const getAllActiveUser = createAsyncThunk(
    "getAllActiveUser",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${ACTIVE_USERS}?page=${data?.page}&size=${data?.limit}&sort={"number":${data?.sort}}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all user without pagination
export const getAllActiveUserWithoutPagination = createAsyncThunk(
    "getAllActiveUserWithoutPagination",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(ACTIVE_USERS);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all inactive user
export const getAllInactiveUser = createAsyncThunk(
    "getAllInactiveUser",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(INACTIVE_USERS);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update user status
export const updateUserStatus = createAsyncThunk(
    "updateUserStatus",
    async (details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`${UPDATE_USER_STATUS}/${details.id}` ,{ isActive: details.isActive });
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// delete user
export const deleteUser = createAsyncThunk(
    "deleteUser",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${USERS}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// update password
export const updatePassword = createAsyncThunk(
    "updatePassword",
    async (details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`users/password/${details.token}`, details.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get all inactive user
export const getUserByUsername = createAsyncThunk(
    "getUserByUsername",
    async (username, { rejectWithValue }) => {
        try {
            const res = await axios.get(`users/public/${username}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// add visitors
export const addVisitor = createAsyncThunk(
    "addVisitor",
    async (visitor_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(VISITORS, visitor_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get all visitors
export const getAllVisitors = createAsyncThunk(
    "getAllVisitors",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${VISITORS}?page=${data?.page}&size=${data?.limit}&sort={"name":${data?.sort}}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// search visitors
export const searchVisitors = createAsyncThunk(
    "searchVisitors",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${VISITORS}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// delete visitor
export const deleteVisitor = createAsyncThunk(
    "deleteVisitor",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${VISITORS}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get visitor by id
export const getVisitorByID = createAsyncThunk(
    "getVisitorByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${VISITORS}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update visitor
export const updateVisitor = createAsyncThunk(
    "updateVisitor",
    async (visitors_details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`${VISITORS}/${visitors_details?.id}`, visitors_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get all templates
export const getAllTemplates = createAsyncThunk(
    "getAllTemplates",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(TEMPLATES);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get Template by id
export const getTemplateByID = createAsyncThunk(
    "getTemplateByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${TEMPLATES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// generate contract
export const generateContract = createAsyncThunk(
    "generateContract",
    async (contract_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${CONTRACT}`, contract_details);
            // const res = await axios.post(`${CONTRACT}/${contract_details?.templateId}/generate`, contract_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get All Contract
export const getAllContracts = createAsyncThunk(
    "getAllContracts",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(CONTRACT);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get Contract by id
export const getContractByID = createAsyncThunk(
    "getContractByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${CONTRACT}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// get Contract by id for update
export const getContractByIDForUpdate = createAsyncThunk(
    "getContractByIDForUpdate",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${CONTRACT}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// delete submitted contract
export const deleteSubmittedContract = createAsyncThunk(
    "deleteSubmittedContract",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${CONTRACT}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);
// update template
export const updateContract = createAsyncThunk(
    "updateContract",
    async (cont_details, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${CONTRACT}/${cont_details?.id}`, cont_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// search Contract
export const searchContract = createAsyncThunk(
    "searchContract",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${CONTRACT}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// upload template
export const templateUpload = createAsyncThunk(
    "templateUpload",
    async (templ_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${TEMPLATES}`, templ_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// delete template
export const deleteTemplate = createAsyncThunk(
    "deleteTemplate",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${TEMPLATES}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// search template
export const searchTemplate = createAsyncThunk(
    "searchTemplate",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${TEMPLATES}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// update template
export const updateTemplate = createAsyncThunk(
    "updateTemplate",
    async (temp_details, { rejectWithValue }) => {
        try {
            const res = await axios.put(`${TEMPLATES}/${temp_details?.id}`, temp_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get all client
export const getAllClients = createAsyncThunk(
    "getAllClients",
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${CLIENT}?page=${data?.page}&size=${data?.limit}&sort={"name":${data?.sort}}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// add client
export const addClient = createAsyncThunk(
    "addClient",
    async (client_details, { rejectWithValue }) => {
        try {
            const res = await axios.post(CLIENT, client_details);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);


// search clients
export const searchClients = createAsyncThunk(
    "searchClients",
    async (query, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${CLIENT}/search/${query}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// get client by id
export const getClientByID = createAsyncThunk(
    "getClientByID",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${CLIENT}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// update client
export const updateClient = createAsyncThunk(
    "updateClient",
    async (client_details, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`${CLIENT}/${client_details?.id}`, client_details?.data);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// delete client
export const deleteClient = createAsyncThunk(
    "deleteClient",
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`${CLIENT}/${id}`);
            return {
                data: res.data,
                statusCode: res.status,
            };
        } catch (err) {
            return rejectWithValue({
                error: err.response.data,
                statusCode: err.response.status,
            });
        }
    }
);

// *********** RTK Middleware End here ***********

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
      clearMessage: (state) => {
        state.message = null;
        state.error = null;
        state.statusCode = null;
        state.appMessage = null;
        state.appError = null;
        state.appStatusCode = null;
      },
      resetSignout: (state) => {
        state.signout = false;
      },
      signoutUser: (state) => {
        // localStorage.clear();
        state.token = null;
        state.role = null;
        state.signout = true;
      },
    },
    extraReducers: (builder) => {



      //   *********** admin Add-Cases Start Here    ***********
  
      // admin login
      builder
        .addCase(adminLogin.pending, (state) => {
            state.loading = true;
        })
        .addCase(adminLogin.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.token = payload.data.token;
            // console.log('sds', payload.data)
            state.statusCode = payload.statusCode;

            localStorage.setItem("token", payload.data.token);

        })
        .addCase(adminLogin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
            state.statusCode = action.payload?.statusCode;
        })
        .addCase(Signup.pending, (state) => {
            state.loading = true;
        })
        .addCase(Signup.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;

        })
        .addCase(Signup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
            state.statusCode = action.payload?.statusCode;
        })
        .addCase(forgotPassword.pending, (state) => {
            state.loading = true;
        })
        .addCase(forgotPassword.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(forgotPassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
            state.statusCode = action.payload?.statusCode;
        })
        .addCase(getCompanies.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCompanies.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            // state.companiesForInvoiceForm = payload.data
        })
        .addCase(getCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllCompanies.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllCompanies.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.companiesForInvoiceForm = payload.data
        })
        .addCase(getAllCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getCompanyByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCompanyByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.companyByIdData = payload.data;
        })
        .addCase(getCompanyByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getCompanyByIDPublic.pending, (state) => {
            state.loading = true;
        })
        .addCase(getCompanyByIDPublic.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.companyByIdDataPublic = payload.data;
        })
        .addCase(getCompanyByIDPublic.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(addCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(addCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(addCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchCompany.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchCompany.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getUserByToken.pending, (state) => {
            state.loading = true;
        })
        .addCase(getUserByToken.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            localStorage.setItem("fullName", payload.data.firstName + " " + payload.data.lastName);
            localStorage.setItem("picture", payload.data.picture);
        })
        .addCase(getUserByToken.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getCoreCompanySetting.pending, (state) => {
            state.appLoading = true;
        })
        .addCase(getCoreCompanySetting.fulfilled, (state, { payload }) => {
            state.appLoading = false;
            state.data = payload.data;
        })
        .addCase(getCoreCompanySetting.rejected, (state, action) => {
            state.appLoading = false;
            state.appError = action.payload?.error?.message;
        })
        .addCase(updateCoreCompanySetting.pending, (state) => {
            state.appLoading = true;
        })
        .addCase(updateCoreCompanySetting.fulfilled, (state, { payload }) => {
            state.appLoading = false;
            // state.data = payload.data;
            state.appMessage = payload.data?.message;
            state.appStatusCode = payload.statusCode;
        })
        .addCase(updateCoreCompanySetting.rejected, (state, action) => {
            state.appLoading = false;
            state.appError = action.payload?.error?.message;
        })
        .addCase(getAllServices.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllServices.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.services = payload.data;
        })
        .addCase(getAllServices.rejected, (state, action) => {
            state.loading = false;
        })
        .addCase(getAllServicesHome.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllServicesHome.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
            state.services = payload.data;
        })
        .addCase(getAllServicesHome.rejected, (state, action) => {
            state.loading = false;
        })
        .addCase(addService.pending, (state) => {
            state.loading = true;
        })
        .addCase(addService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(addService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateService.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            // state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getServiceByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getServiceByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getServiceByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteService.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchService.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchService.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.services = payload.data;
        })
        .addCase(searchService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoices.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoices.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getInvoices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.invoiceByIdData = payload.data.invoice;
        })
        .addCase(getInvoiceByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceByIDPublic.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceByIDPublic.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.invoiceByIdDataPublic = payload?.data?.invoice;
            console.log("---",payload)
        })
        .addCase(getInvoiceByIDPublic.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceNumber.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceNumber.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.invoiceNextNumber = payload.data;
        })
        .addCase(getInvoiceNumber.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getInvoiceSecretNumber.pending, (state) => {
            state.loading = true;
        })
        .addCase(getInvoiceSecretNumber.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.invoiceSecretNumber = payload.data;
        })
        .addCase(getInvoiceSecretNumber.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(genInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(genInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(genInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            // state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchInvoice.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllActiveUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllActiveUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.activeUsers = payload.data;
        })
        .addCase(getAllActiveUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllActiveUserWithoutPagination.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllActiveUserWithoutPagination.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.activeUsers = payload.data;
        })
        .addCase(getAllActiveUserWithoutPagination.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllInactiveUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllInactiveUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getAllInactiveUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateUserStatus.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateUserStatus.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            console.log(payload)
        })
        .addCase(updateUserStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteUser.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
        })
        .addCase(deleteUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updatePassword.pending, (state) => {
            state.loading = true;
        })
        .addCase(updatePassword.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })    
        .addCase(updatePassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getUserByUsername.pending, (state) => {
            state.loading = true;
        })
        .addCase(getUserByUsername.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.userByUserNameData = payload.data;
        })
        .addCase(getUserByUsername.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(addVisitor.pending, (state) => {
            state.loading = true;
        })
        .addCase(addVisitor.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(addVisitor.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllVisitors.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllVisitors.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getAllVisitors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchVisitors.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchVisitors.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchVisitors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteVisitor.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteVisitor.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteVisitor.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getVisitorByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getVisitorByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getVisitorByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateVisitor.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateVisitor.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateVisitor.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllTemplates.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllTemplates.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getAllTemplates.rejected, (state, action) => {
            state.loading = false;
        })
        .addCase(getTemplateByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getTemplateByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getTemplateByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(generateContract.pending, (state) => {
            state.loading = true;
        })
        .addCase(generateContract.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            // state.data = payload.data;
            state.contractXMlResponse = payload?.data;
            console.log("abc", payload)
            state.statusCode = payload.statusCode;
        })
        .addCase(generateContract.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllContracts.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllContracts.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getAllContracts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getContractByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getContractByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.contractData = payload.data;
        })
        .addCase(getContractByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getContractByIDForUpdate.pending, (state) => {
            state.loading = true;
        })
        .addCase(getContractByIDForUpdate.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.contractData = payload.data;
        })
        .addCase(getContractByIDForUpdate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteSubmittedContract.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteSubmittedContract.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteSubmittedContract.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateContract.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateContract.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateContract.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchContract.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchContract.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchContract.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(templateUpload.pending, (state) => {
            state.loading = true;
        })
        .addCase(templateUpload.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(templateUpload.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteTemplate.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteTemplate.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchTemplate.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchTemplate.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateTemplate.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateTemplate.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getAllClients.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllClients.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getAllClients.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(addClient.pending, (state) => {
            state.loading = true;
        })
        .addCase(addClient.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(addClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(searchClients.pending, (state) => {
            state.loading = true;
        })
        .addCase(searchClients.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(searchClients.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(getClientByID.pending, (state) => {
            state.loading = true;
        })
        .addCase(getClientByID.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data;
        })
        .addCase(getClientByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(updateClient.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateClient.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.data = payload.data;
            state.statusCode = payload.statusCode;
        })
        .addCase(updateClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        .addCase(deleteClient.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteClient.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.message = payload.data?.message;
            state.statusCode = payload.statusCode;
        })
        .addCase(deleteClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.error?.message;
        })
        
      //   ***********    admin Add-Cases End Here    ***********

    }
  });


export const { clearMessage, resetSignout, signoutUser } = adminSlice.actions;
export default adminSlice.reducer;