import moment from "moment";
import React, { useState } from "react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteClient } from "../../store/adminSlice";
import DeletePopup from "../../common/DeletePopup";
import { Link } from "react-router-dom";

export default function ClientCard({ item, onClientDeleted }) {
  const dispatch = useDispatch();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedClientId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteClient(selectedClientId)).then(() => {
      onClientDeleted(selectedClientId);
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div className="profile-card w-full max-w-xs bg-white dark:bg-secondaryDarkBg rounded-lg shadow-lg group overflow-hidden relative snap-start shrink-0 flex flex-col items-center justify-center gap-4 p-6  hover:shadow-xl border-t-2 hover:border-t-usetheme transition-all ease-in-out duration-300 group">
        {/* Avatar Section */}
        <div className="avatar w-24 h-24 rounded-full overflow-hidden border-2 border-usetheme flex items-center justify-center ">
          <img
            src="/assets/images/no_image.jpg"
            alt="Client"
            className="w-full h-full object-cover"
          />
          {/* Overlay for the entire card */}
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 dark:group-hover:opacity-70 group-hover:opacity-15 transition-opacity duration-300 flex items-center justify-center"></div>
        </div>

        {/* Headings */}
        <div className="headings text-center">
          <p className="text-xl font-semibold text-gray-800 dark:text-white capitalize">
            {item?.name || "John Doe"}
          </p>
          {/* <p className="text-sm font-medium text-gray-600 dark:text-white">Developer</p> */}
        </div>

        {/* Contact Info */}
        <div className="w-full">
          <ul className="flex flex-col items-start gap-2">
            <li className="flex items-center gap-2 text-gray-600 dark:text-white hover:text-usetheme dark:hover:text-usetheme">
              <FaPhoneAlt className="text-base" />
              <span className="text-sm font-medium">
                {item?.phone || "+923038765432"}
              </span>
            </li>
            <li className="flex items-center gap-2 text-gray-600 dark:text-white hover:text-usetheme dark:hover:text-usetheme">
              <FaEnvelope className="text-base" />
              <span className="text-xs font-medium">
                {item?.email || "abc@gmail.com"}
              </span>
            </li>
            <li className="flex items-center gap-2 text-gray-600 dark:text-white hover:text-usetheme dark:hover:text-usetheme">
              <svg
                className="fill-current text-base"
                height="15"
                width="15"
                id="code-icon"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  {/* <!-- Left Arrow --> */}
                  <path d="M8.41 16.59L3.83 12l4.58-4.59L7 6l-6 6 6 6 1.41-1.41z"></path>
                  {/* <!-- Right Arrow --> */}
                  <path d="M15.59 16.59L20.17 12l-4.58-4.59L17 6l6 6-6 6-1.41-1.41z"></path>
                  {/* <!-- Forward Slash --> */}
                  <path d="M13.41 4l-4 16h1.72l4-16h-1.72z"></path>
                </g>
              </svg>

              <span className="text-xs font-medium">
                {item?.techStack || "Wordpress, React"}
              </span>
            </li>
            <li className="flex items-center gap-2 text-gray-600 dark:text-white hover:text-usetheme dark:hover:text-usetheme">
              <svg
                className="fill-current text-base"
                id="map"
                viewBox="0 0 16 16"
                height="15"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current text-base"
                  d="M8 0C5.2 0 3 2.2 3 5s4 11 5 11 5-8.2 5-11-2.2-5-5-5zm0 8C6.3 8 5 6.7 5 5s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
                ></path>
              </svg>
              <span className="text-xs font-medium">
                {item?.address || "Peshawar, Pakistan"}
              </span>
            </li>
          </ul>
          <div className="flex justify-end items-center space-x-4 mt-4">
            <Link
              to={`update-client/${item?._id}`}
              className="text-gray-600 hover:text-blue-600 cursor-pointer p-2 border-[#EBF0ED] dark:border-white border rounded-lg bg-[#FAFAFA] dark:bg-secondaryDarkBg"
            >
              <img
                className="max-w-8 max-h-8 md:max-w-16 md:max-h-16 dark:filter dark:invert"
                src="/assets/icons/edit-icon.svg"
                alt=""
              />
            </Link>
            <button
              onClick={() => handleDeleteClick(item._id)}
              className="text-gray-600 hover:text-red-600 cursor-pointer p-2 border-[#EBF0ED] dark:border-white border rounded-lg bg-[#FAFAFA] dark:bg-secondaryDarkBg"
            >
              <img
                className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                src="/assets/icons/delete-icon.svg"
                alt=""
              />
            </button>
          </div>
        </div>

        {/* Bottom Divider */}
        {/* <hr className="w-full h-[3px] bg-usetheme mt-4" /> */}
      </div>

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
