import { ENABLE_ENTER_SUBMIT } from "../config/enterSubmissionConfig";

export const handleEnterSubmit = (e, submitCallback) => {
    if (ENABLE_ENTER_SUBMIT && e.key === "Enter") {
      e.preventDefault();
      submitCallback();
    }
  };



  export const applyThemeMode = (themeMode) => {
    const root = document.documentElement;
  
    if (themeMode === 'auto') {
      const applySystemMode = () => {
        const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        root.classList.toggle('dark', isSystemDark);
      };
      applySystemMode();
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', applySystemMode);
    } else {
      root.classList.toggle('dark', themeMode === 'dark');
    }
    localStorage.setItem('themeMode', themeMode);
  };