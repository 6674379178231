import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, Signup } from "../store/adminSlice";
import { toast } from "react-toastify";
import { applyThemeMode, handleEnterSubmit } from "../constant";

export default function SignupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, statusCode } = useSelector(
    (state) => state.admin
  );

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [themeColor, setThemeColor] = useState("#ed1c24");


  useEffect(() => {
    // Load saved color from localStorage when the page loads
    const savedColor = localStorage.getItem("themeColor") || "#ed1c24"; // Default if not found
    setThemeColor(savedColor);
    document.documentElement.style.setProperty("--tw-colors-usetheme", savedColor);
    applyThemeMode(localStorage.getItem('themeMode'))
  }, []);

  const handleSignup = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (username === "" || email === "" || password === "") {
      toast.error("Please Fill All the Fields ...");
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
    } else {
      dispatch(Signup({ username, email, password }));
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      navigate("/");
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className=" h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4] dark:bg-primaryDarkBg">
        <div
          className="w-full md:w-fit p-4 md:p-10 bg-white dark:bg-secondaryDarkBg flex flex-col items-center rounded-3xl"
          style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
        >
          <img
            className="mx-auto h-14 w-14"
            src="/assets/images/netchain-logo.svg"
            alt=""
          />
          <div className="font-bold tracking-wide dark:text-white text-xl md:text-2xl mt-5 mb-2 text-center">
            Register Your Account
          </div>
          {/* <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Login to your Office Dashboard
        </div> */}
          <form
            onKeyDown={(e) => handleEnterSubmit(e, handleSignup)}
            className="w-full"
          >
            <div className="w-full md:w-[30rem] mt-4">
              <label className="form-control ">
                <div className="label">
                  <span className="text-xs md:text-base font-bold tracking-wide dark:text-white">
                    Enter Your Username
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="@netchainmedia"
                  className="p-3 md:p-3 rounded-lg text-sm dark:text-white border border-[#EBF0ED] bg-[#FAFAFA] dark:bg-secondaryDarkBg w-full focus:outline-none focus:border-[#EBF0ED]"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </label>
              <label className="form-control ">
                <div className="label">
                  <span className="text-xs md:text-base font-bold tracking-wide dark:text-white">
                    Enter Your Email Address
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="mike.tyson@mail.com"
                  className="p-3 md:p-3 rounded-lg text-sm border dark:text-white border-[#EBF0ED] bg-[#FAFAFA] dark:bg-secondaryDarkBg w-full focus:outline-none focus:border-[#EBF0ED]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <label className="form-control">
                <div className="label">
                  <span className="text-xs md:text-base font-bold tracking-wide dark:text-white">
                    Enter Your Password
                  </span>
                </div>
                <span className="flex items-center relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    placeholder="********"
                    className="p-3 md:p-3 rounded-lg text-sm border dark:text-white border-[#EBF0ED] bg-[#FAFAFA] dark:bg-secondaryDarkBg w-full focus:outline-none focus:border-[#EBF0ED]"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordVisible ? (
                    <IoEyeOutline
                      className="absolute right-4 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <IoEyeOffOutline
                      className="absolute right-4 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </span>
              </label>
              <button
                onClick={handleSignup}
                disabled={loading}
                className="bg-usetheme hover:bg-opacity-90 h-12 duration-200 w-full rounded-lg py-3 mt-[1rem] text-white font-bold tracking-wider"
              >
                {!loading ? (
                  "Register My Account"
                ) : (
                  <span className="loading loading-spinner loading-md"></span>
                )}
              </button>
              <div className=" mt-3 flex justify-center items-center gap-1">
                <span className="font-[400] text-sm dark:text-white">
                  Already have an account?
                </span>
                <Link
                  to="/"
                  className="text-usetheme text-[15px] font-semibold"
                >
                  Login
                </Link>
              </div>
            </div>
          </form>
          {/* <div className="text-[#C19A6B] text-end mt-3 font-bold tracking-wide cursor-pointer">
          Forgot Password?
        </div> */}
        </div>
      </div>
    </>
  );
}
