import React, { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import BreadCrums from "../../common/BreadCrums";
import ClientCard from "./ClientCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients, searchClients } from "../../store/adminSlice";
import ButtonLink from "../buttons/ButtonLink";
import Pagination from "../../common/Pagination";

export default function AllClients() {
  const dispatch = useDispatch();
  const { token, data, loading, message, error, statusCode } = useSelector(
    (state) => state.admin
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [clients, setClients] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [sorting, setSorting] = useState(-1);

  useEffect(() => {
    if (data?.clients) {
      setClients(data?.clients);
    }
  }, [data?.clients]);

  const handleClientDeleted = (deletedClientId) => {
    setClients((prevClient) =>
      prevClient.filter((client) => client._id !== deletedClientId)
    );
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(
            getAllClients({ page: currentPage, limit: itemsPerPage, sort: sorting })
        );
      } else {
        dispatch(searchClients(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(
            getAllClients({ page: currentPage, limit: itemsPerPage, sort: sorting })
          );
        } else {
          dispatch(searchClients(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    dispatch(
      getAllClients({ page: currentPage, limit: itemsPerPage, sort: sorting })
    );
  }, [currentPage, itemsPerPage]);

  const totalPages = data?.pagination?.totalPages || 0;

  // pagination changes
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handlePageSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    setCurrentPage(selectedPage);
  };

  const handleVisitorDeleted = (deletedVisitorId) => {
    setClients((prevVisitor) =>
      prevVisitor.filter((visitor) => visitor._id !== deletedVisitorId)
    );
  };

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Clients",
              },
            ]}
          />
          <ButtonLink to="add-client" text="Add New Client" />
        </div>
        <div className="relative flex items-center w-full mb-4">
          <img
            src="/assets/icons/search.svg"
            alt="Search Icon"
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-medium text-sm md:text-base"
            type="search"
            placeholder="Search Clients .."
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>
      <div className=" py-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {clients?.length > 0 ? (
              clients?.map((item) => (
                <ClientCard
                  key={item._id}
                  item={item}
                  onClientDeleted={handleClientDeleted}
                />
              ))
            ) : (
              <>
                <div className="dark:text-white">No Clients Found</div>
              </>
            )}
          </div>
        )}
      </div>


      {/* Pagination Controls */}
      {!loading && clients?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handlePageSelectChange={handlePageSelectChange}
        />
      )}
    </>
  );
}
