import React, { useEffect, useState } from "react";
import {
  clearMessage,
  getAllContracts,
  searchContract,
} from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";
import SubmittedContractCard from "./SubmittedContractCard";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import ButtonLink from "../buttons/ButtonLink";
import { toast } from "react-toastify";

export default function SubmittedContracts() {
  const dispatch = useDispatch();
  const { loading, data, message, error, statusCode } = useSelector(
    (state) => state.admin
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (data?.contracts) {
      setContracts(data?.contracts);
    }
  }, [data?.contracts]);

  const handleContractDeleted = (deletedContractId) => {
    setContracts((prevContracts) =>
      prevContracts.filter((contract) => contract._id !== deletedContractId)
    );
  };

  useEffect(() => {
    dispatch(getAllContracts());
  }, [dispatch]);

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(getAllContracts());
      } else {
        dispatch(searchContract(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(getAllContracts());
        } else {
          dispatch(searchContract(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    if (statusCode === 200 && message) {
      toast.success(message);
    } else if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [statusCode, message, error, dispatch]);

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              { name: "Admin Dashboard", path: "/admin-dashboard/" },
              { name: "Contracts", path: "" },
            ]}
          />
          <ButtonLink
            to="/admin-dashboard/contract-templates"
            text="Generate New Contract"
          />
        </div>
        <div className="relative flex items-center w-full mb-4">
          <img
            src="/assets/icons/search.svg"
            alt="Search Icon"
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-medium text-sm md:text-base"
            type="search"
            placeholder="Search Contracts ..."
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>

      <div className=" py-4 mt-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {contracts?.length > 0
              ? contracts?.map((item) => (
                  <SubmittedContractCard
                    key={item._id}
                    item={item}
                    onContractDeleted={handleContractDeleted}
                  />
                ))
              : "No Contract Found"}
          </div>
        )}
      </div>
    </>
  );
}
