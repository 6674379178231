import React, { useEffect, useState } from "react";
import AdmindashboardModulesCard from "./AdmindashboardModulesCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getUserByToken,
  searchService,
} from "../../store/adminSlice";
import BreadCrums from "../../common/BreadCrums";
import Loader from "../../common/Loader";

export default function AdminDashboardModules() {
  const dispatch = useDispatch();
  const { token, services, loading } = useSelector((state) => state.admin);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    dispatch(getUserByToken(token));
    dispatch(getAllServices());
  }, [dispatch, token]);

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);

    // Clear the previous timeout if a new search is triggered
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(getAllServices());
      } else {
        dispatch(searchService(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(getAllServices());
        } else {
          dispatch(searchService(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
              },
            ]}
          />
        </div>
        <div className="relative flex items-center w-full mb-4">
          <img
            src="/assets/icons/search.svg"
            alt="Search Icon"
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white dark:bg-secondaryDarkBg border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-medium text-sm md:text-base"
            type="search"
            placeholder="Search Services By Name .."
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>
      <div className=" py-4 mt-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {services?.services?.length > 0
              ? services?.services?.map((item) => (
                  <AdmindashboardModulesCard key={item._id} item={item} />
                ))
              : "No Services Found"}
          </div>
        )}
      </div>
    </>
  );
}
