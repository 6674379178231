import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, generateContract, getTemplateByID } from "../../store/adminSlice";
import { useNavigate, useParams } from "react-router-dom";
import mammoth from "mammoth";
import axios from "axios";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { toast } from "react-toastify";
import BreadCrums from "../../common/BreadCrums";
import Loader from "../../common/Loader";
import ButtonLink from "../buttons/ButtonLink";
import ButtonForm from "../buttons/ButtonForm";

export default function CreateContract() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templateID, templateName } = useParams();
  const { loading, data, contractXMlResponse, message, statusCode, error } = useSelector(
    (state) => state.admin
  );
  const [extractedFields, setExtractedFields] = useState([]);
  const [templateHtml, setTemplateHtml] = useState("");
  const [formData, setFormData] = useState({});

  useEffect(() => {
    dispatch(getTemplateByID(templateID));
  }, [dispatch, templateID]);

  // Convert .docx to HTML and extract fields
  useEffect(() => {
    const convertDocxToHtmlAndExtractFields = async () => {
      if (data?.docxTemplate) {
        try {
          const response = await axios.get(data.docxTemplate, {
            responseType: "arraybuffer",
          });

          // Convert the .docx file to HTML using mammoth
          const result = await mammoth.convertToHtml({
            arrayBuffer: response.data,
          });
          let htmlContent = result.value;

          // Extract fields using regex to match {field_name}
          const regex = /{([^}]+)}/g;
          const fields = [...htmlContent.matchAll(regex)].map(
            (match) => match[1]
          );

          setExtractedFields(fields); // Store the extracted fields

          // Replace placeholders with editable spans
          const editableHtml = htmlContent.replace(regex, (match, fieldName) => {
            return `<span contenteditable="true" data-field="${fieldName}" id="${fieldName}" class="editable-field dark:text-black">${fieldName}</span>`;
          });
          

          setTemplateHtml(editableHtml);
        } catch (error) {
          console.error(
            "Error converting .docx to HTML or extracting fields:",
            error
          );
        }
      }
    };

    convertDocxToHtmlAndExtractFields();
  }, [data?.docxTemplate]);

  const handleInputChange = (event) => {
    const fieldName = event?.target?.getAttribute("data-field"); 
    const currentFieldValue = event?.target?.innerText; 
  
    // Get all elements with the same field name
    const fieldElements = document.querySelectorAll(`[data-field="${fieldName}"]`);
  
    fieldElements.forEach((element) => {
      if (element !== event.target) {
        element.innerText = currentFieldValue; 
      }
    });
  
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: currentFieldValue, 
    }));
  
    console.log("Updated Data:", formData);
  };
  

  // Validate the form fields
  const validateFields = () => {
    for (const field of extractedFields) {
      if (!formData[field] || formData[field].trim() === "") {
        toast.error(`Please fill out the field: ${field}`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleInputChange();

    // if (!validateFields()) return;

    const payload = {
      ...formData,
      templateId: templateID,
    };

    dispatch(generateContract(payload));
  };


  useEffect(() => {
    if (message === "Generated successfully") {
      toast.success(message);
      navigate('/admin-dashboard/contracts')
    } else if (error) {
      // toast.error(error);
    }

   
    dispatch(clearMessage());
  }, [statusCode, message, error, dispatch]);

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
        <BreadCrums
          breadCrum={[
            {
              name: "Admin Dashboard",
              path: "/admin-dashboard/",
            },
            {
              name: "Contracts",
              path: "/admin-dashboard/contract-templates",
            },
            {
              name: "Create Contract",
            },
          ]}
        />
        <ButtonLink to="/admin-dashboard/contract-templates" text="Cancel" />
      </div>
      <div className="editor-container max-w-xs lg:max-w-4xl mx-auto p-4 bg-white dark:bg-secondaryDarkBg dark:text-white my-4">
        {loading ? (
          <div className="flex justify-center items-center h-[60vh]">
            <Loader />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {/* Render editable template content */}
            <div
              dangerouslySetInnerHTML={{ __html: templateHtml }}
              className="template-container text-wrap"
              onInput={handleInputChange}
            />

            {/* <button type="submit">Submit Contract</button> */}
          </form>
        )}

        <div className="flex justify-end">
          <ButtonForm
            onClick={handleSubmit}
            loading={loading}
            text="Create Contract"
          />
        </div>
      </div>
    </>
  );
}
