import React, { useEffect, useRef, useState } from "react";
import { CiMenuFries } from "react-icons/ci";
import { RiDashboardFill } from "react-icons/ri";
import { IoSettingsOutline, IoPersonAdd } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { RiCloseLargeLine } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import Drawer from "../components/drawer/Drawer";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
// import { MdOutlinePowerSettingsNew } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import {
  MdOutlinePowerSettingsNew,
  MdLightMode,
  MdDarkMode,
  MdSettingsSystemDaydream,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  getCoreCompanySetting,
  updateCoreCompanySetting,
} from "../store/adminSlice";
import { applyThemeMode } from "../constant";

const AdminDashboardLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { appLoading, data, appMessage, appError } = useSelector((state) => state.admin);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [themeColor, setThemeColor] = useState("#ed1c24");

  const [formData, setFormData] = useState({
    themeMode: localStorage.getItem('themeMode'),
  });


  const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode'));

  useEffect(() => {
    if(!localStorage.getItem('themeMode')){
      dispatch(getCoreCompanySetting());
    }
    else{
      applyThemeMode(localStorage.getItem('themeMode'))
    }
  }, [dispatch]);

  // Update theme mode based on API response
  useEffect(() => {
    if (data && data?.coreSettings) {
      setThemeMode(data?.coreSettings.themeMode || localStorage.getItem('themeMode'));
      applyThemeMode(data?.coreSettings.themeMode || localStorage.getItem('themeMode'));
    }
  }, [data]);

  // Handle theme selection and API update
  const handleThemeChange = (mode) => {
    setThemeMode(mode);
    applyThemeMode(mode);
    dispatch(updateCoreCompanySetting({ themeMode: mode })); // API call to update theme in backend
  };



  const toggleUserDropdown = () => {
    setIsUserDropdownOpen((prev) => !prev);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setIsUserDropdownOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    // Load saved color from localStorage when the page loads
    const savedColor = localStorage.getItem("themeColor") || "#ed1c24"; 
    setThemeColor(savedColor);
    document.documentElement.style.setProperty(
      "--tw-colors-usetheme",
      savedColor
    );
  }, []);

  useEffect(() => {
    if (appMessage || appError) {
      dispatch(clearMessage())
    }
  }, [appMessage, appError]);


  return (
    <>
      <div className="relative flex h-screen bg-gray-100 dark:bg-primaryDarkBg max-w-[1920px] 3xl:mx-auto">
        {/* Sidebar start here */}
        <div
          className={`fixed inset-y-0 left-0 3xl:left-auto bg-white dark:bg-secondaryDarkBg border-r dark:border-none transition-all duration-300 z-20 ${
            isSidebarOpen ? "w-56" : "w-16"
          } flex flex-col justify-between`}
        >
          {/* Sidebar Toggle Button */}
          <div>
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="m-4 focus:outline-none"
            >
              {isSidebarOpen ? (
                <RiCloseLargeLine className="dark:text-white" size={30} /> // Cross icon when open
              ) : (
                <CiMenuFries className="dark:text-white" size={30} /> // Menu icon when closed
              )}
            </button>

            {/* Main Navigation Items */}
            <div className="mt-10 flex-grow">
              <NavLink
                to="/admin-dashboard"
                end
                className={({ isActive }) =>
                  `flex items-center p-4 cursor-pointer whitespace-nowrap overflow-hidden ${
                    isActive
                      ? "bg-usetheme dark:bg-buttonDarkBg dark: text-white"
                      : "text-black dark:text-white hover:bg-usetheme dark:hover:bg-buttonDarkBg  hover:text-white"
                  }`
                }
              >
                <SidebarItem
                  icon={<RiDashboardFill size={30} />}
                  title="Dashboard"
                  isOpen={isSidebarOpen}
                />
              </NavLink>
              <NavLink
                to="/admin-dashboard/all-users"
                className={({ isActive }) =>
                  `flex items-center p-4 cursor-pointer whitespace-nowrap overflow-hidden ${
                    isActive
                      ? "bg-usetheme dark:bg-buttonDarkBg dark: text-white"
                      : "text-black dark:text-white hover:bg-usetheme dark:hover:bg-buttonDarkBg  hover:text-white"
                  }`
                }
              >
                <SidebarItem
                  icon={<IoPersonAdd size={30} />}
                  title="All Users"
                  isOpen={isSidebarOpen}
                />
              </NavLink>
            </div>
          </div>

          {/* Settings Navigation Item at the Bottom */}
          <div className="mb-4">
            <NavLink
              to="/admin-dashboard/settings"
              className={({ isActive }) =>
                `flex items-center p-4 cursor-pointer whitespace-nowrap overflow-hidden ${
                  isActive
                    ? "bg-usetheme dark:bg-buttonDarkBg dark: text-white"
                    : "text-black dark:text-white hover:bg-usetheme dark:hover:bg-buttonDarkBg  hover:text-white"
                }`
              }
            >
              <SidebarItem
                icon={<IoSettingsOutline size={30} />}
                title="Setting"
                isOpen={isSidebarOpen}
              />
            </NavLink>
          </div>
        </div>

        {/* Sidebar end here */}

        {/* Main content area start here */}
        <div className="flex-1 flex flex-col justify-between ml-16">
          {/* Header */}
          <header className="bg-white dark:bg-secondaryDarkBg p-2 shadow-md sm:px-10 sm:py-3">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-lg font-bold dark:text-white">OFFICE</h1>
              </div>
              <div className="flex items-center">
                <div className="">
                  <Drawer />
                </div>
                <div className="">
                  <div className="dropdown dropdown-end">
                    <div
                      tabIndex={0}
                      role="button"
                      className="btn btn-ghost rounded-btn"
                      onClick={toggleDropdown}
                    >
                      <img
                        className="bg-[#F7F7F7] dark:bg-white p-2 rounded-full"
                        src="/assets/icons/notification.svg"
                        alt="Notifications"
                      />
                    </div>
                    {isOpen && (
                      <ul
                        className="menu dropdown-content bg-white rounded-box z-[1] mt-4 w-52 p-1 shadow absolute"
                        onClick={closeDropdown}
                      >
                        <li>
                          <span>netchain joins today</span>
                        </li>
                        <li>
                          <span>netchain generate on contract</span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>

                {/* User Dropdown */}
                <div className="relative">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={toggleUserDropdown}
                  >
                    <span className="hidden sm:block dark:text-white">
                      {localStorage.getItem("fullName") &&
                      localStorage.getItem("fullName") !== "undefined undefined"
                        ? localStorage.getItem("fullName")
                        : "Hello User"}
                    </span>{" "}
                    <img
                      className="w-8 h-8 rounded-full border border-usetheme"
                      src={
                        localStorage.getItem("picture") &&
                        localStorage.getItem("picture") !== "undefined" &&
                        localStorage.getItem("picture") !== null
                          ? localStorage.getItem("picture")
                          : "/assets/icons/avatar-icon-2.svg"
                      }
                      alt="User Avatar"
                    />
                    {/* Username */}
                    <MdExpandLess
                      className={` dark:text-white transition-transform ${
                        isUserDropdownOpen ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  </div>
                  {isUserDropdownOpen && (
                    <div ref={dropdownRef} className="absolute right-0 mt-2 w-56 bg-white dark:bg-secondaryDarkBg dark:text-white rounded-lg shadow-lg z-10">
                      <ul className="py-2">
                        <Link to="/admin-dashboard/profile-setting">
                          <li className="flex items-center p-2 hover:bg-gray-100 dark:hover:bg-primaryDarkBg cursor-pointer border-b-2">
                            <GoPerson className="w-5 h-5 mr-2" />
                            <span>Profile Setting</span>
                          </li>
                        </Link>
                        <li className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-primaryDarkBg cursor-pointer border-b-2">
                          <span className="flex">
                            <MdSettingsSystemDaydream className="w-5 h-5 mr-2" />
                            <span>Theme</span>
                          </span>
                          <div className="flex space-x-2 bg-gray-100 rounded-full shadow-lg">
                            <MdSettingsSystemDaydream
                              title="System Mode"
                              className={`w-7 h-7 cursor-pointer p-1 ${
                                themeMode === "auto"
                                  ? "text-blue-500 bg-white rounded-full border border-gray-400"
                                  : "text-gray-500"
                              } hover:text-blue-700`}
                              onClick={() => handleThemeChange("auto")}
                            />
                            <MdLightMode
                              title="Light Mode"
                              className={`w-7 h-7 cursor-pointer p-1 ${
                                themeMode === "light"
                                  ? "text-yellow-400 bg-white rounded-full border border-gray-400"
                                  : "text-gray-500"
                              } hover:text-yellow-600`}
                              onClick={() => handleThemeChange("light")}
                            />
                            <MdDarkMode
                              title="Dark Mode"
                              className={`w-7 h-7 cursor-pointer p-1 ${
                                themeMode === "dark"
                                  ? "text-gray-500 bg-white rounded-full border border-gray-400"
                                  : "text-gray-500"
                              } hover:text-gray-600`}
                              onClick={() => handleThemeChange("dark")}
                            />
                          </div>
                        </li>
                        <Link to="/">
                          <li className="flex items-center p-2 hover:bg-gray-100 dark:hover:bg-primaryDarkBg cursor-pointer ">
                            <MdOutlinePowerSettingsNew className="w-5 h-5 mr-2" />
                            <span>Sign Out</span>
                          </li>
                        </Link>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>

          {/* here the children will come and the sidebar and header will be the same */}
          <main className="px-4 py-1 overflow-y-auto h-[100vh]">
            {children}
          </main>
          <footer className="footer footer-center bg-zinc-200 dark:bg-secondaryDarkBg text-base-content dark:text-white p-1 sm:p-2 font-semibold text-[10px] sm:text-sm xl:text-base">
            <aside>
              <p>
                Copyright © {new Date().getFullYear()} - All right reserved by
                <span className="font-bold">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://netchainmedia.com/"
                  >
                    {" "}
                    NetChain Media
                  </a>
                </span>
              </p>
            </aside>
          </footer>
        </div>
        {/* Main content area end here */}
      </div>
    </>
  );
};

const SidebarItem = ({ icon, title, isOpen }) => {
  return (
    <>
      <span className="text-xl">{icon}</span>
      {isOpen && <span className="ml-4">{title}</span>}
    </>
  );
};

export default AdminDashboardLayout;
